import React from 'react';
import Store from '../components/Store';
import './StorePage.css';

const StorePage = () => {
  return (
    <div className="store-page">
      <h2>Merchandise Store</h2>
      <Store />
    </div>
  );
};

export default StorePage;
