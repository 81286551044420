import React from 'react';
import ReactAudioPlayer from 'react-audio-player';
import './SongList.css';

const songs = [
  {
    id: 1,
    name: 'The Shape of Things to Come',
    singer: 'Mx Martin',
    musicSrc: '/songs/The Shape of Things to Come.wav'
  },
  {
    id: 2,
    name: 'Mood Swings',
    singer: 'Mx Martin',
    musicSrc: '/songs/Mood Swings.m4a'
  },
  // Add more songs as needed
];

const SongList = () => {
  return (
    <div className="song-list">
      {songs.map(song => (
        <div key={song.id} className="song-item">
          <h3>{song.name} - {song.singer}</h3>
          <ReactAudioPlayer
            src={song.musicSrc}
            controls
          />
        </div>
      ))}
    </div>
  );
};

export default SongList;

