import React from 'react';
import SongList from '../components/SongList';
import './Listen.css';

const Listen = () => {
  return (
    <div className="listen">
      <h2>Listen to Our Music</h2>
      <SongList />
    </div>
  );
};

export default Listen;
