import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Home from './pages/Home';
import Listen from './pages/Listen';
import StorePage from './pages/StorePage';
import './transitions.css';

const Main = () => {
  const location = useLocation();

  return (
    <TransitionGroup>
      <CSSTransition
        key={location.key}
        classNames="page"
        timeout={300}
      >
        <Routes location={location}>
          <Route path="/" element={<Home />} />
          <Route path="/listen" element={<Listen />} />
          <Route path="/store" element={<StorePage />} />
        </Routes>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default Main;

